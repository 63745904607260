import React from 'react';
import Layout from '../components/Layout';
// import { graphql } from "gatsby"
// import Title from "../components/Title"
// import Image from "gatsby-image"
import Jobs from '../components/Jobs';
import Skills from '../components/Skills';
import Bio from '../components/Bio';
import Hero from '../components/Hero';
import SEO from '../components/SEO';

const About = () => {
  // const { info, stack, title, image } = nodes[0]

  return (
    <Layout>
      <SEO
        title="About | Alex Polcastro"
        description="I'm Alex Policastro. I create things to make people's lives better! At my core, I am a helper. My goal is to do everything with a positive attitude and to help my clients achieve their vision."
        url="/about"
      />

      <section className="about-page" role="main">
        <Hero />
        <div
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-delay="300"
          data-sal-easing="easeInOutSine"
        >
          <Bio />
        </div>
        {/* <div className="section-center about-center">
          <Image fluid={image.childImageSharp.fluid} className="about-img" />
          <article className="about-text">
            <Title title={title} />
            <p>{info}</p>
            <div className="about-stack">
              {stack.map(item => {
                return <span key={item.id}>{item.title}</span>
              })}
            </div>
          </article>
        </div> */}
        <div
          data-sal="fade"
          data-sal-duration="900"
          data-sal-delay="100"
          data-sal-easing="easeInOutSine"
        >
          <Skills top />
        </div>
        <div
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-delay="100"
          data-sal-easing="easeInOutSine"
        >
          <Jobs />
        </div>
      </section>
    </Layout>
  );
};

// export const query = graphql`
//   {
//     about: allStrapiAbout {
//       nodes {
//         stack {
//           id
//           title
//         }
//         title
//         info
//         image {
//           childImageSharp {
//             fluid {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default About;
