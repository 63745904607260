import React from 'react';
import classNames from 'classnames';
import { FaLaptopCode, FaPaintBrush } from 'react-icons/fa';
import '../css/skills.scss';

const Skills = ({ top }) => {
  return (
    <section
      className={classNames({
        'skills-area': true,
        top,
      })}
    >
      <div className="container">
        <div className="skills-wrapper">
          <article className="skill">
            <FaPaintBrush className="social-icon"></FaPaintBrush>

            <h3 className="position">Designer</h3>
            <h4 className="position-description">UX/UI & Graphic</h4>
            <h4>Things I design</h4>
            <p>UX, UI, Web, Mobile, Apps, Logos, Brand Identity</p>
            <h4>Aesthetic</h4>
            <p>Clean, modern, bright, fun</p>
            <h4>Design Tools</h4>
            <p>
              Adobe Suite, XD, Illustrator, After Effects, Premier
              Pro, Photoshop Sketch
            </p>
          </article>
          <article className="skill">
            <FaLaptopCode className="social-icon"></FaLaptopCode>
            <h3 className="position">Developer</h3>
            <h4 className="position-description">Front-End Web</h4>
            <h4>Things I build</h4>
            <p>Websites, Web Apps, Shopify Stores</p>
            <h4>Languages</h4>
            <p> HTML, CSS, Sass, JS, PHP, TS</p>
            <h4>Libraries</h4>
            <p>React, Gatsby, Next.js, MERN Stack</p>
            <h4>Services</h4>
            <p>
              AWS, MongoDB, Heroku, Google APIs & Services, Shopify,
              Pantheon
            </p>
            <h4>Dev Tools</h4>
            <p>Atom, VS Code, Terminal</p>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Skills;
