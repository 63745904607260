import React from 'react';
import { FaHatWizard, FaClock } from 'react-icons/fa';
import { graphql, useStaticQuery } from 'gatsby';
import Title from './Title';
// import { Link } from "gatsby"
import '../css/jobs.scss';

const query = graphql`
  {
    allStrapiJobs(sort: { fields: strapiId, order: DESC }) {
      nodes {
        id
        order
        strapiId
        company
        date
        position
        desc {
          id
          name
        }
      }
    }
  }
`;

const Jobs = () => {
  const data = useStaticQuery(query);
  const {
    allStrapiJobs: { nodes: jobs },
  } = data;
  // const [value, setValue] = React.useState(0)
  // const { company, position, date, desc } = jobs[value]

  return (
    <section className="section jobs-section">
      <div className="container">
        <Title title="work history" />
        <div className="time-line">
          {jobs
            .sort((a, b) => {
              if (a.order < b.order) return -1;
              if (a.order > b.order) return 1;
              return 0;
            })
            .map(job => {
              return (
                <div
                  key={job.id}
                  className="job"
                  data-sal="fade"
                  data-sal-duration="900"
                  data-sal-delay="100"
                  data-sal-easing="easeInOutSine"
                >
                  <div className="date">
                    <FaClock className="job-icon" />
                    {job.date}
                  </div>
                  <div className="position">{job.position}</div>
                  <div className="company">{job.company}</div>

                  <div className="descriptions">
                    {job.desc.map(item => {
                      return (
                        <div key={item.id} className="job-desc">
                          <FaHatWizard className="job-icon" />
                          <p>{item.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* <Link to="/about" className="btn center-btn">
        more info
      </Link> */}
    </section>
  );
};

export default Jobs;
